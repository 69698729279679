import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';

import { Direction } from '../../lib/types';

const partialOpacity = 0.8;
const partialScale = 0.8;

const gap = 16;

const useRegisterCarousel = () => {
  const containerRef = useRef<HTMLDivElement>();
  const [leftArrowActive, setLeftArrowActive] = useState(false);
  const [rightArrowActive, setRightArrowActive] = useState(true);
  const [step, setStep] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const handlers = useSwipeable({
    onSwipedRight: () =>
      leftArrowActive && !isPending && handleArrowClick(Direction.left),
    onSwipedLeft: () =>
      rightArrowActive && !isPending && handleArrowClick(Direction.right),
  });

  useEffect(() => {
    if (typeof window === 'undefined' || !containerRef.current) return;

    setStep(0);
    (containerRef.current.childNodes as NodeListOf<HTMLElement>).forEach(
      (item, index) => {
        if (!containerRef.current) return;
        const scale = index === 0 ? 1 : index === 1 ? partialScale : 0;
        const translateX = index * ((isDesktop ? 450 : 283) + gap);
        const opacity = index === 0 ? 1 : index === 1 ? partialOpacity : 0;

        item.style.transform = `translateX(${translateX}px) scale(${scale})`;
        item.style.opacity = `${opacity}`;
        item.style.filter = index === 0 ? 'none' : 'blur(8px)';
        item.style.userSelect = index === 0 ? 'auto' : 'none';
      },
    );
  }, [isDesktop]);

  const handleArrowClick = (direction: Direction) => {
    if (!containerRef.current || isPending) return;

    (containerRef.current.childNodes as NodeListOf<HTMLElement>).forEach(
      (item, index) => {
        const style = window.getComputedStyle(item);
        const matrix = new WebKitCSSMatrix(style.transform);

        const translateX =
          matrix.m41 - ((isDesktop ? 450 : 283) + gap) * direction;
        const scale = index === step + direction ? 1 : partialScale;

        const opacity =
          index === step + direction
            ? 1
            : index === step + direction + 1
              ? partialOpacity
              : 0;

        item.style.transform = `translateX(${translateX}px) scale(${scale})`;
        item.style.filter = index === step + direction ? 'none' : 'blur(8px)';
        item.style.opacity = `${opacity}`;
        item.style.userSelect = index === step + direction ? 'auto' : 'none';
      },
    );

    setStep((step) => step + direction);
  };

  useEffect(() => {
    if (!containerRef.current) return;

    setLeftArrowActive(step > 0);
    setRightArrowActive(
      step <
        (containerRef.current.childNodes as NodeListOf<HTMLElement>)?.length -
          1,
    );

    setIsPending(true);
    const timeout = setTimeout(() => {
      setIsPending(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [step]);

  return {
    containerRef,
    handleArrowClick,
    leftArrowActive,
    rightArrowActive,
    handlers,
    currentStep: step,
  };
};

export default useRegisterCarousel;
