import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';

import Button from './carousel/Button';
import { getStringData } from '../../lib/strapi';
import { Direction } from 'apps/web/src/lib/types';
import HowToRegisterCard from './HowToRegisterCard';
import useRegisterCarousel from '../../hooks/react/useRegisterCarousel';
import type { CardHeroCard, SectionHowToRegister } from '@eva/cms-types';

const partialScale = 0.8;
const partialOpacity = 0.8;

const gap = 16;
const mobileWidth = 283;
const desktopWidth = 450;
const desktopBreakpoint = 1024;

interface Props {
  data: SectionHowToRegister['attributes'];
}

function HowToRegisterCarousel(props: Props) {
  const {
    handlers,
    currentStep,
    containerRef,
    leftArrowActive,
    rightArrowActive,
    handleArrowClick,
  } = useRegisterCarousel();

  const cards = props.data.cards as unknown as CardHeroCard['attributes'][];

  const { title } = getStringData(props.data);

  return (
    <>
      <section
        id="HowToRegister"
        className="bg-brand py-16 desktop:py-40 overflow-x-hidde overflow-y-visible"
      >
        <div className="desktop:hidden block px-6">
          <ReactMarkdown
            components={{
              p: (props) => (
                <p className="text-h3 text-white text-center">
                  {props.children}
                </p>
              ),
              strong: (props) => (
                <strong className="text-h3 text-accent text-center">
                  {props.children}
                </strong>
              ),
            }}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
            children={title}
          />
        </div>
        <div className="grid grid-cols-content desktop:grid-cols-contentRegister mt-12">
          <div className="hidden desktop:flex flex-col justify-center col-[2] my-auto desktop:mr-6 relative bg-brand h-full z-10 ml-6">
            <ReactMarkdown
              components={{
                p: (props) => (
                  <p className="text-h1 text-white">{props.children}</p>
                ),
                strong: (props) => (
                  <strong className="text-h1 text-accent">
                    {props.children}
                  </strong>
                ),
              }}
              rehypePlugins={[rehypeRaw, rehypeSanitize]}
              children={title}
            />
            <div className="hidden desktop:flex gap-2 mt-16">
              <Button
                onclick={handleArrowClick}
                direction={Direction.left}
                disabled={!leftArrowActive}
              />
              <Button
                onclick={handleArrowClick}
                direction={Direction.right}
                disabled={!rightArrowActive}
              />
            </div>
          </div>

          <div
            {...handlers}
            className="col-[3_span/3_span] desktop:col-[3/3_span] relative overflow-x-hidden overflow-y-visible"
          >
            <div
              className="justify-center desktop:justify-start overflow-x-hidden snap-x snap-proximity flex gap-2 scroll-smooth pb-4 min-h-[35rem]"
              ref={containerRef}
            >
              {cards?.map((card, index) => {
                const transform =
                  index *
                  ((window.innerWidth > desktopBreakpoint
                    ? desktopWidth
                    : mobileWidth) +
                    gap);

                const scale = index === 0 ? 1 : partialScale;
                const opacity = `${
                  index === 0 ? 1 : index === 1 ? partialOpacity : 0
                }`;
                const filter = index === 0 ? 'none' : 'blur(8px)';
                const userSelect = index === 0 ? 'auto' : 'none';

                return (
                  <HowToRegisterCard
                    card={card}
                    key={index}
                    index={index}
                    active={currentStep === index}
                    style={{
                      transform: `translateX(${transform}px) scale(${scale})`,
                      opacity,
                      filter,
                      userSelect,
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowToRegisterCarousel;
