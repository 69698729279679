import { useEffect, useRef } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';

import { getStringData } from '../../lib/strapi';
import type { CardHeroCard } from '@eva/cms-types';
import registration from '../../lottie/registration.json';
import registrace from '../../lottie/registrace.json';
import pozvanka from '../../lottie/pozvanka.json';
import zabezpeceni from '../../lottie/zabezpeceni.json';
import nakup from '../../lottie/nakup.json';
import profile from '../../lottie/profile.json';

const animations = [pozvanka, registrace, zabezpeceni, profile, nakup];

interface Props {
  card: CardHeroCard['attributes'];
  style?: React.CSSProperties;
  active?: boolean;
  index: number;
}

function HowToRegisterCard(props: Props) {
  const { title, description } = getStringData(props.card);
  const ref = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (props.active) {
      ref.current?.play();
    } else {
      ref.current.stop();
    }
  }, [props.active]);

  return (
    <div
      className="origin-left overflow-hidden flex-1 w-[17.6875rem] desktop:w-[28.125rem] min-h-[22rem] desktop:min-h-[35rem] snap-start absolute transition-all duration-500"
      style={props.style}
    >
      <div className="h-full min-h-[22rem] desktop:min-h-[35rem] rounded-[2rem] bg-white p-11">
        <div className="w-fit mb-8">
          <Lottie
            animationData={animations[props.index] ?? registration}
            loop={false}
            autoPlay={false}
            lottieRef={ref}
            className="h-32"
          />
        </div>
        <div className="mt-auto w-full flex flex-col gap-8">
          <h3 className="text-h3 text-dark">{title}</h3>
          <div className="text-body1">{description}</div>
        </div>
      </div>
    </div>
  );
}

export default HowToRegisterCard;
